import { get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import imageItem from "../../../assets/images/image_item.png";
import { IDataCollection, onDeleteCollection } from "../service";

interface Props {
  data: IDataCollection[];
  onReloadTable: () => void;
}

const CampaignCard: React.FC<Props> = (props) => {
  const { data } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const onDelete = async (id: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <button className="btn-close" onClick={onClose}>
              x
            </button>
            <div className="title">Are you sure?</div>
            <div className="content">Are you sure you want to do this?</div>

            <div className="mt-3" style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                size="sm"
                onClick={async () => {
                  await onSubmitDelete(id);
                  onClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                variant="danger"
                size="sm"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        );
      },
      overlayClassName: "overlayClassName",
      closeOnClickOutside: false,
    });
  };

  const onSubmitDelete = async (id: string) => {
    setLoading(true);
    try {
      await onDeleteCollection(id);
      props.onReloadTable();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="mt-1">
        {data && data.length > 0
          ? data.map((d: IDataCollection, key: number) => (
              <Col
                xs={4}
                className="mb-4 hover-box-content-item"
                key={`data_key_${key + 1}`}
              >
                <div className="box-content-item">
                  <div className="btn-delete" onClick={() => onDelete(d._id)}>
                    x
                  </div>
                  <img
                    src={get(d, "avatar.image") || imageItem}
                    width={"100%"}
                    className="image-box-compaign"
                  />
                  <div className="padding-box">
                    <div className="d-flex-between">
                      <div className="expiry-date">
                        <span className="opacity">Expires</span> •{" "}
                        {moment(d.expiredDate).format("MM/DD/YYYY")}
                      </div>
                      <div className="expiry-date">
                        <span className="opacity">Category</span> • {d.category}
                      </div>
                      <div className="expiry-date">
                        <span className="opacity">Symbol</span> • {d.symbol}
                      </div>
                      <div className="expiry-date">
                        <span className="opacity">Price</span>
                        {Number(d.price) > 0
                          ? `$${d.price.toFixed(2)}`
                          : "Free"}
                      </div>
                      <div className="expiry-date">
                        <span className="opacity">Sold</span> {d.totalSold}/
                        {d.supplyNfts}
                      </div>
                    </div>
                    <div className="ntf-compaign">
                      {d.name || d.nftName}
                      <div className="expired-sold-out ntf-top">
                        {d.soldOut ? (
                          <div className="sold-out">SOLD OUT</div>
                        ) : (
                          ""
                        )}
                        {d.isExpired ? (
                          <div className="sold-out">EXPIRED</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className="compaign-content f-14"
                      dangerouslySetInnerHTML={{ __html: d.description }}
                    ></div>
                  </div>
                </div>
              </Col>
            ))
          : ""}
      </Row>
    </>
  );
};

export default CampaignCard;
